/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}

:root, .light, .light-theme {
  --grass-a1: #00c00004;
  --grass-a2: #0099000a;
  --grass-a3: #00970016;
  --grass-a4: #009f0725;
  --grass-a5: #00930536;
  --grass-a6: #008f0a4d;
  --grass-a7: #018b0f6b;
  --grass-a8: #008d199a;
  --grass-a9: #008619b9;
  --grass-a10: #007b17c1;
  --grass-a11: #006514d5;
  --grass-a12: #002006df;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --grass-a1: #00bc3104;
      --grass-a2: #008c2309;
      --grass-a3: #008f2315;
      --grass-a4: #008c2222;
      --grass-a5: #00872032;
      --grass-a6: #007d1d46;
      --grass-a7: #00781b60;
      --grass-a8: #00741b85;
      --grass-a9: #006c179f;
      --grass-a10: #006014a8;
      --grass-a11: #2a7e3b;
      --grass-a12: #203c25;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --grass-a1: color(display-p3 .024 .757 .024 / .016);
        --grass-a2: color(display-p3 .024 .565 .024 / .036);
        --grass-a3: color(display-p3 .059 .576 .008 / .083);
        --grass-a4: color(display-p3 .035 .565 .008 / .134);
        --grass-a5: color(display-p3 .047 .545 .008 / .197);
        --grass-a6: color(display-p3 .031 .502 .004 / .275);
        --grass-a7: color(display-p3 .012 .482 .004 / .377);
        --grass-a8: color(display-p3 0 .467 .008 / .522);
        --grass-a9: color(display-p3 .008 .435 0 / .624);
        --grass-a10: color(display-p3 .008 .388 0 / .659);
        --grass-a11: color(display-p3 .263 .488 .261);
        --grass-a12: color(display-p3 .151 .233 .153);
      }
    }
  }
}

:root, .light, .light-theme {
  --grass-1: #fbfefb;
  --grass-2: #f5fbf5;
  --grass-3: #e9f6e9;
  --grass-4: #daf1db;
  --grass-5: #c9e8ca;
  --grass-6: #b2ddb5;
  --grass-7: #94ce9a;
  --grass-8: #65ba74;
  --grass-9: #46a758;
  --grass-10: #3e9b4f;
  --grass-11: #2a7e3b;
  --grass-12: #203c25;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --grass-1: #fbfefb;
      --grass-2: #f5fbf5;
      --grass-3: #e9f7e9;
      --grass-4: #daf0db;
      --grass-5: #c9e9ca;
      --grass-6: #b2deb5;
      --grass-7: #94ce9a;
      --grass-8: #65ba74;
      --grass-9: #46a758;
      --grass-10: #3e9a4f;
      --grass-11: #2a7e3b;
      --grass-12: #203c25;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --grass-1: color(display-p3 .986 .996 .985);
        --grass-2: color(display-p3 .966 .983 .964);
        --grass-3: color(display-p3 .923 .965 .917);
        --grass-4: color(display-p3 .872 .94 .865);
        --grass-5: color(display-p3 .811 .908 .802);
        --grass-6: color(display-p3 .733 .864 .724);
        --grass-7: color(display-p3 .628 .803 .622);
        --grass-8: color(display-p3 .477 .72 .482);
        --grass-9: color(display-p3 .38 .647 .378);
        --grass-10: color(display-p3 .344 .598 .342);
        --grass-11: color(display-p3 .263 .488 .261);
        --grass-12: color(display-p3 .151 .233 .153);
      }
    }
  }
}

:root, .light, .light-theme {
  --red-a1: #ff000003;
  --red-a2: #ff000008;
  --red-a3: #f3000d14;
  --red-a4: #ff000824;
  --red-a5: #ff000632;
  --red-a6: #f8000442;
  --red-a7: #df000356;
  --red-a8: #d2000571;
  --red-a9: #db0007b7;
  --red-a10: #d10005c1;
  --red-a11: #c40006d3;
  --red-a12: #55000de8;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --red-a1: #b8000003;
      --red-a2: #eb000007;
      --red-a3: #d7000013;
      --red-a4: #ff100f22;
      --red-a5: #f9000030;
      --red-a6: #e2000040;
      --red-a7: #ca000054;
      --red-a8: #be00006d;
      --red-a9: #cc0000ac;
      --red-a10: #c20000b6;
      --red-a11: #ce2c31;
      --red-a12: #641723;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --red-a1: color(display-p3 .675 .024 .024 / .012);
        --red-a2: color(display-p3 .863 .024 .024 / .028);
        --red-a3: color(display-p3 .792 .008 .008 / .075);
        --red-a4: color(display-p3 1 .008 .008 / .134);
        --red-a5: color(display-p3 .918 .008 .008 / .189);
        --red-a6: color(display-p3 .831 .02 .004 / .251);
        --red-a7: color(display-p3 .741 .016 .004 / .33);
        --red-a8: color(display-p3 .698 .012 .004 / .428);
        --red-a9: color(display-p3 .749 .008 0 / .675);
        --red-a10: color(display-p3 .714 .012 0 / .714);
        --red-a11: color(display-p3 .744 .234 .222);
        --red-a12: color(display-p3 .36 .115 .143);
      }
    }
  }
}

:root, .light, .light-theme {
  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #ffdbdc;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #eb8e90;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ce2c31;
  --red-12: #641723;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --red-1: #fffcfc;
      --red-2: #fff7f7;
      --red-3: #feebec;
      --red-4: #fdd;
      --red-5: #ffcecf;
      --red-6: #fdbdbe;
      --red-7: #f4a9aa;
      --red-8: #eb8e90;
      --red-9: #e5484d;
      --red-10: #dd3e42;
      --red-11: #ce2c31;
      --red-12: #641723;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --red-1: color(display-p3 .998 .989 .988);
        --red-2: color(display-p3 .995 .971 .971);
        --red-3: color(display-p3 .985 .925 .925);
        --red-4: color(display-p3 .999 .866 .866);
        --red-5: color(display-p3 .984 .812 .811);
        --red-6: color(display-p3 .955 .751 .749);
        --red-7: color(display-p3 .915 .675 .672);
        --red-8: color(display-p3 .872 .575 .572);
        --red-9: color(display-p3 .83 .329 .324);
        --red-10: color(display-p3 .798 .294 .285);
        --red-11: color(display-p3 .744 .234 .222);
        --red-12: color(display-p3 .36 .115 .143);
      }
    }
  }
}

:root, .light, .light-theme {
  --teal-a1: #00cc9905;
  --teal-a2: #00aa800c;
  --teal-a3: #00c69d1f;
  --teal-a4: #00c39633;
  --teal-a5: #00b49047;
  --teal-a6: #00a6855e;
  --teal-a7: #0099807c;
  --teal-a8: #009783ac;
  --teal-a9: #009e8ced;
  --teal-a10: #009684f2;
  --teal-a11: #008573;
  --teal-a12: #00332df2;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --teal-a1: #00bd8704;
      --teal-a2: #00a1790b;
      --teal-a3: #00b9901b;
      --teal-a4: #00af8a2b;
      --teal-a5: #00a07f3d;
      --teal-a6: #008e7251;
      --teal-a7: #00816c6b;
      --teal-a8: #007e6b91;
      --teal-a9: #007866b3;
      --teal-a10: #00715eb9;
      --teal-a11: #007f6d;
      --teal-a12: #0d3d38;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --teal-a1: color(display-p3 .024 .757 .514 / .016);
        --teal-a2: color(display-p3 .02 .647 .467 / .044);
        --teal-a3: color(display-p3 .004 .741 .557 / .106);
        --teal-a4: color(display-p3 .004 .702 .537 / .169);
        --teal-a5: color(display-p3 .004 .643 .494 / .24);
        --teal-a6: color(display-p3 .004 .569 .447 / .318);
        --teal-a7: color(display-p3 .004 .518 .424 / .42);
        --teal-a8: color(display-p3 0 .506 .424 / .569);
        --teal-a9: color(display-p3 0 .482 .404 / .702);
        --teal-a10: color(display-p3 0 .451 .369 / .726);
        --teal-a11: color(display-p3 .08 .5 .43);
        --teal-a12: color(display-p3 .11 .235 .219);
      }
    }
  }
}

:root, .light, .light-theme {
  --teal-1: #fafefd;
  --teal-2: #f3fbf9;
  --teal-3: #e0f8f3;
  --teal-4: #ccf3ea;
  --teal-5: #b8eae0;
  --teal-6: #a1ded2;
  --teal-7: #83cdc1;
  --teal-8: #53b9ab;
  --teal-9: #12a594;
  --teal-10: #0d9b8a;
  --teal-11: #008573;
  --teal-12: #0d3d38;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --teal-1: #fafefd;
      --teal-2: #f3fbf9;
      --teal-3: #e0f8f3;
      --teal-4: #ccf3ea;
      --teal-5: #b8ebe0;
      --teal-6: #a1ded2;
      --teal-7: #83cdc1;
      --teal-8: #53b9ab;
      --teal-9: #12a594;
      --teal-10: #0d9b8a;
      --teal-11: #007f6d;
      --teal-12: #0d3d38;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --teal-1: color(display-p3 .983 .996 .992);
        --teal-2: color(display-p3 .958 .983 .976);
        --teal-3: color(display-p3 .895 .971 .952);
        --teal-4: color(display-p3 .831 .949 .92);
        --teal-5: color(display-p3 .761 .914 .878);
        --teal-6: color(display-p3 .682 .864 .825);
        --teal-7: color(display-p3 .581 .798 .756);
        --teal-8: color(display-p3 .433 .716 .671);
        --teal-9: color(display-p3 .297 .637 .581);
        --teal-10: color(display-p3 .275 .599 .542);
        --teal-11: color(display-p3 .08 .5 .43);
        --teal-12: color(display-p3 .11 .235 .219);
      }
    }
  }
}

